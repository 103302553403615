<template>
  <div class="d-flex import-recap">
    <v-card class="overflow-y-auto flex-shrink-0 file-types">
      <v-list>
        <v-list-group
          v-for="(entity, id) in entities"
          :key="id"
          v-model="entity.active"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{$icon(`i.${toPascalCase(entity.entity)}`)}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{entity.data_source + '/' + entity.entity}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon
              v-if="nbrErrors(entity)"
              :class="{'mr-n6': !entity.nbrWarnings}"
              class="ml-1"
            >
              <v-chip
                class="px-2"
                color="error"
                small
              >
                {{nbrErrors(entity)}}
              </v-chip>
            </v-list-item-icon>
            <v-list-item-icon
              v-if="nbrWarnings(entity)"
              class="ml-1 mr-n6"
            >
              <v-chip
                class="px-2"
                color="warning"
                small
              >
                {{nbrWarnings(entity)}}
              </v-chip>
            </v-list-item-icon>
          </template>

          <v-list-item
            v-for="file in entity.files"
            :key="file.filename"
            @click="changeTable(file, entity)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{file.filename}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="file.nbr_errors">
              <v-chip
                class="px-2"
                color="error"
                small
              >
                {{file.nbr_errors}}
              </v-chip>
            </v-list-item-action>
            <v-list-item-action
              v-if="file.nbr_warnings"
              class="ml-1"
            >
              <v-chip
                class="px-2"
                color="warning"
                small
              >
                {{file.nbr_warnings}}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <v-card class="mr-2 flex-grow-1 overflow-x-auto">
      <v-data-table
        class="text-no-wrap"
        dense
        :headers="header"
        :items-per-page="10"
        :items="logs"
        fixed-header
        multi-sort
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item="i">
          <tr>
            <td>
              <v-icon>{{$icon(`i.${toPascalCase(i.item.severity)}`)}}</v-icon>
            </td>
            <td
              v-for="(value, index) in header.slice(1)"
              :key="index"
              :class="{
              'error': i.item.errors.some(x => x.field == value.value && x.severity == 'Error'),
              'warning': i.item.errors.some(x => x.field == value.value && x.severity == 'Warning'),
              }"
              @click="showError(i.item.errors, value.value)"
            >
              <!-- {{i}} -->
              {{i.item.line[`${value.value}`]}}
              <!-- {{key}} // {{i.item[`${value.value}`]}} // {{value}} // {{i.item}} -->
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  watch: {
    '$route.params.id': {
      immediate: true,
      handler (id) {
        if (!id && this.duplicationInprogress) {
          this.duplicationInprogress = false
        } else {
          if (!this.documentId) {
            this.id = id
            this.refreshDocument()
          }
        }
      }
    }
  },
  computed: {
  },
  data () {
    return {
      entities: [],
      headers: [
        { text: this.$t('t.ImportDataSources'), value: 'data_source', width: '5%' },
        { text: this.$t('t.Entity'), value: 'entity', width: '8%' },
        { text: this.$t('t.File'), value: 'file' },
        { text: this.$t('t.Column'), value: 'column' },
        { text: this.$t('t.Field'), value: 'field' },
        { text: this.$t('t.Line'), value: 'line' },
        { text: this.$t('t.Message'), value: 'msg' },
        { text: this.$t('t.Severity'), value: 'severity_msg' }
      ],
      header: [],
      footer: {
        itemsPerPageOptions: [20]
      },
      currentFile: null,
      logs: []
    }
  },
  methods: {
    isSelected (id) {
      return id === this.dataDocumentType
    },
    async changeTable (file, entity) {
      this.currentFile = file.filename
      const entityDataSource = entity.data_source.concat('/', entity.entity)
      const r = await this.$http().get('/core/import/' + this.id + '/' + entityDataSource + '/' + file.filename)
      const tmp = [{ text: this.$t('t.Severity'), value: 'severity' }]
      tmp.push(...r.data.header.map(col => {
        const newCol = {}
        newCol.text = this.$t(`t.${this.toPascalCase(col)}`)
        newCol.value = col
        return newCol
      }))
      this.header = tmp
      this.logs = r.data.errors
    },
    async refreshDocument () {
      const r = await this.$http().get('/core/import/' + this.id)
      this.entities = r.data
      this.dataLoading = false
    },
    nbrWarnings (entity) {
      return entity.files.reduce((acc, file) => acc + file.nbr_warnings, 0)
    },
    nbrErrors (entity) {
      return entity.files.reduce((acc, file) => acc + file.nbr_errors, 0)
    },
    showError (errors, value) {
      const error = errors.filter(x => x.field === value)
      if (error.length !== 0) {
        console.log(error)
      }
    }
  },
  props: {
  }
}
</script>

<style lang="stylus" scoped>
.import-recap
  width 100%

.file-types
  width 20%
  scrollbar-gutter stable

table > tbody > tr > td:nth-child(1), table > thead > tr > th:nth-child(1)
  position sticky !important
  position -webkit-sticky !important
  left 0
  z-index 9998
  background white
  overflow-y auto

table > thead > tr > th:nth-child(1)
  z-index 9999
</style>
